<template>
  <div :class="$style.OperatingModeInfo">
    <h1>Внимание!</h1>
    <p>
      С {{ automaticModeTime }} по МСК обменный пункт работает в автоматическом
      режиме. Поддержка доступна с {{ technicalSupportTime }} по МСК.
    </p>
    <p>
      Все направления обмена сейчас работают автоматически. Если потребуется
      помощь, обратитесь к поддержке в рабочее время.
    </p>
    <p>
      Нажимая "Я согласен(-на)", вы подтверждаете, что ознакомлены и готовы
      продолжить обмен.
    </p>
    <UiButtonVButton @click="$emit('unblockCalculator')">
      Я согласен(-на)
    </UiButtonVButton>
  </div>
</template>

<script setup lang="ts">
defineEmits(["unblockCalculator"]);

const props = defineProps({
  isWeekend: {
    type: Boolean,
    required: true,
  },
});

const automaticModeTime = computed(() => {
  return props.isWeekend ? "22:00 до 10:00" : "00:00 до 08:00";
});

const technicalSupportTime = computed(() => {
  return props.isWeekend ? "10:00 до 22:00" : "08:00 до 00:00";
});
</script>

<style lang="scss" module>
.OperatingModeInfo {
  padding: 0 9.4rem;
  border: 2px solid var(--body-color);
  background: var(--techmode-bg);
  z-index: 3;
  text-align: center;
  backdrop-filter: blur(7.5px);
  display: flex;
  flex-direction: column;
  gap: 3rem;
  align-items: center;
  justify-content: center;

  @include respond-to(sm) {
    padding: 0 43px;
  }

  @include respond-to(xs) {
    padding: 0 20px;
  }

  h1 {
    font-family: $font-family-secondary;
    font-size: 2.4rem;
    line-height: 140%;
  }
}
</style>
